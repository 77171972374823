import React, { useState } from 'react'
import { Modal, message } from 'antd'
import axios from 'axios'
import { strapiURL } from '../../config'
import { CloseModalIcon } from '../icons'

const CandidateKeepCancelModal = ({
  data,
  visible,
  onClose,
  onOk,
  onCancel,
}) => {
  const [loading, setLoading] = useState(false)

  const handleCancelPlan = async () => {
    setLoading(true)
    await axios.post(strapiURL + '/stripe/candidateCancelSchedulePlan')
    message.success('Successfully cancelled scheduled Searchable Profile')
    onOk()
  }

  return (
    <Modal
      width={600}
      footer={null}
      visible={visible}
      okText="Save"
      className="p-0 candidateAlertModal shadow-lg"
    >
      <div className="relative bg-white rounded-br px-4 pt-4 pb-4 flex flex-col items-center rounded-md">
        <div
          className="absolute right-4 top-4 cursor-pointer"
          onClick={onClose}
        >
          <CloseModalIcon />
        </div>

        <h4 className="text-voyage font-bold text-lg">
          Stay connected to top employers and recruiters—keep your profile
          active for $4.99 per month
        </h4>
        <p className="font-sans text-center mb-0 text-voyage  text-md mt-2">
          You are currently scheduled to switch to Searchable Profile product on{' '}
          {data?.date}. Are you sure you want to cancel this planned change?
        </p>

        <div className="flex items-center justify-center mt-6 w-11/12  mx-auto">
          <button
            onClick={handleCancelPlan}
            className="text-white py-4 px-10 bg-voyage cursor-pointer font-sans font-bold rounded mr-8"
          >
            {loading ? 'Proceeding...' : 'Yes'}
          </button>

          <button
            id="cancelMembership"
            onClick={onCancel}
            className="bg-bgSecondaryButton text-voyage sm:w-auto text-sm font-bold font-sans py-4 px-10 rounded"
          >
            No
          </button>
        </div>
      </div>
    </Modal>
  )
}

export default CandidateKeepCancelModal
