import React from 'react'
import { Link } from 'gatsby'
const PageNav = ({ navFirstItem, navFirstItemLink, navItem }) => {
  return (
    <div className="container mx-auto px-6 sm:px-0">
      <ul className="flex item-center py-10 mb-0 ml-0">
        {navFirstItem ? (
          <li className="mb-0">
            <Link
              className="font-sans text-xs font-normal text-voyage hover:text-voyage mr-2"
              to={navFirstItemLink}
            >
              {navFirstItem} <span className="mx-1">/</span>
            </Link>
          </li>
        ) : (
          <li className="mb-0">
            <Link
              className="font-sans text-xs font-normal text-voyage hover:text-voyage mr-2"
              to="/"
            >
              Home <span className="mx-1">/</span>
            </Link>
          </li>
        )}
        <li className="mb-0">
          <span className="font-sans text-xs font-normal" to="/">
            {navItem}
          </span>
        </li>
      </ul>
    </div>
  )
}
export default PageNav
