import React, { useEffect, useState, useRef, useMemo } from 'react'
import { message, Table, Tag } from 'antd'
import moment from 'moment'
import { logout, setProducts } from '../../../../redux/actions/user'
import { useWindowSize } from '../../../../../windowResize'
import 'react-quill/dist/quill.snow.css'
import UpdatePasswordModal from '../../../Modals/updatePassword'
import axios from 'axios'
import { strapiURL, auth0Roles } from '../../../../config'
import cookie from 'react-cookies'
import { useSelector, useDispatch } from 'react-redux'
import { Link, navigate } from 'gatsby'
import CandidateUpdateImageModal from '../../../Modals/updateCandidateImageModal'
import {
  permissionsHandler,
  formatCurrency,
  dateFormatHandler,
} from '../../../../functions'
import useGMT from '../../../../hooks/useGTM'
import { paramsReturnHandler } from '../../../../data'
import CandidateCancelModal from '../../../Modals/candidateCancel'
import CandidateKeepCancelModal from '../../../Modals/candidateKeepCancel'

const CandidateAccount = ({ userProfile, user, setUserProfile, location }) => {
  let _windowSize = useWindowSize()
  const [emailLoading, setEmailLoading] = useState(false)
  const [modalOpen, setModalOpen] = useState(false)
  const [imageModalOpen, setImageModalOpen] = useState(false)
  const [invoices, setInvoices] = useState([])
  const [PurchaseDate, setPurchaseDate] = useState(null)
  const [loading, setLoading] = useState(false)
  const [submitLoading, setSubmitLoading] = useState(false)
  const [cancelSubModal, setCancelSubModal] = useState(false)
  const [cancelScheModal, setCancelScheModal] = useState(false)
  const [cancelOfferId, setCancelOfferId] = useState(null)
  const emailInputRef = useRef(null)

  let largeScreen = _windowSize?.width && _windowSize?.width > 1023
  const storeProducts = useSelector(state => state.user).products
  let products = permissionsHandler(storeProducts, user?.role?.id)
  const dispatch = useDispatch()

  const { pushCancelSubscriptionEvent } = useGMT()

  const emailChangeHandler = () => {
    let value = (emailInputRef.current.value || '').toLowerCase()
    if (!value || value === userProfile?.user?.email) {
      message.error('Please enter a new email address.')
      return
    } else {
      setEmailLoading(true)
      let data = {
        userId: userProfile?.user?.auth0_user_id,
        email: value,
      }
      axios
        .post(strapiURL + '/auth0/user/update/email', data)
        .then(res => {
          message.success('Updated successfully')
          setEmailLoading(false)
          cookie.remove('user', { path: '/' })
          setTimeout(() => {
            if (typeof window !== 'undefined') {
              window.location.href = '/'
            }
          }, 500)
          dispatch(logout())
        })
        .catch(err => {
          emailInputRef.current.value = userProfile.username
          message.error('User with this email already exist')
          setEmailLoading(false)
        })
    }
  }

  useEffect(() => {
    if (user?.customer_id && !invoices.length) {
      let _lineItems = []
      if (!_lineItems.length) {
        setLoading(true)
        axios
          .post(strapiURL + '/stripe/invoices', {
            customer_id: user.customer_id,
          })
          .then(res => {
            setLoading(false)
            if (res?.data.charges?.data?.length) {
              res?.data?.charges?.data?.forEach(transaction => {
                if (transaction?.invoice?.lines?.data?.length) {
                  transaction?.invoice.lines.data.forEach(item => {
                    let itemDescription = item.description
                    if (item.type === 'invoiceitem' && item.quantity) {
                      itemDescription =
                        item.quantity + ' \u00d7 ' + item.description
                    }

                    _lineItems.push({
                      ...item,
                      invoice_id: item.id,
                      created: transaction.created,
                      currency: transaction.currency,
                      status: transaction.status?.toUpperCase(),
                      description: itemDescription,
                      invoice_doc: transaction.invoice?.invoice_pdf,
                    })
                  })
                } else {
                  res?.data?.charges?.data?.forEach(transaction => {
                    _lineItems.push({
                      ...transaction,
                      invoice_id: transaction?.id,
                      created: transaction.created,
                      currency: transaction.currency,
                      status: transaction.status?.toUpperCase(),
                      invoice_doc: transaction.invoice?.invoice_pdf,
                    })
                  })
                }
              })
            }

            setInvoices(_lineItems)
          })
          .catch(err => {
            message.error('Something went wrong.')
          })
      }
    }
    if (user?.role?.id === auth0Roles?.Member?.id) {
      let _subs = storeProducts || {}
      let memberData = {
        currentSubscription: null,
      }
      if (_subs?.subscribedProducts?.length) {
        let latestSubscribedProduct = _subs?.subscribedProducts.sort(
          (a, b) => b.id - a.id
        )[0]
        memberData.currentSubscription = latestSubscribedProduct
      }
      setPurchaseDate(memberData?.currentSubscription?.created_at || null)
    }
  }, [user, invoices])

  useEffect(() => {
    if (location.search && user) {
      let _search = paramsReturnHandler(location.search)
      if (_search.method === 'reactivate') {
        redirectToPortal()
      }
    }
  }, [location, user])

  useEffect(() => {
    handleLoadProducts()
  }, [])

  useEffect(() => {
    if (
      window?.location?.hostname == 'www.medreps.com' ||
      window?.location?.hostname == 'medreps.com'
    ) {
      handleCancelSubscription()
    }
  }, [products])
  const handleLoadProducts = async () => {
    if (user) {
      const { data: products } = await axios.get(
        strapiURL + `/employers/${user?.user?.id}/subscriptionsAndCredits`
      )

      dispatch(setProducts(products))
    }
  }

  const handleCancelSubscription = () => {
    const ChurnBuster = window.ChurnBuster || {}
    ChurnBuster.cancelAttributes = {
      accountId: '454db1ca-3d55-491d-882c-721783a358dd',
      source: 'stripe',
      customerId: user?.customer_id,
      customerEmail: user?.user?.email,
      subscriptionId: products?.subscription?.subscription,
      cancelButtonSelector: '#cancelMembership',
      liveMode: true,
    }

    var script = document.createElement('script')
    script.type = 'text/javascript'
    script.async = true
    script.src =
      'https://proxy-assets.churnbuster.io/v4/scripts.js?v=1678742553659'
    var insert = document.getElementsByTagName('script')[0]
    insert.parentNode.insertBefore(script, insert)
  }

  const handleUpdateCancelOffer = value => {
    if (cancelOfferId) {
      axios
        .put(strapiURL + `/cancel-offers/${cancelOfferId}`, {
          offer_accepted: value,
        })
        .then(res => {
          setCancelOfferId(null)
        })
    }
  }

  const handleCreateCancelOffer = () => {
    axios
      .post(strapiURL + '/cancel-offers', {
        user: user?.user?.id,
        offer_accepted: false,
      })
      .then(res => {
        setCancelOfferId(res?.data?.id)
      })
  }

  const handleDownloadInvoice = invoiceUrl => {
    const link = document.createElement('a')
    link.href = invoiceUrl
    link.setAttribute('download', `FileName.pdf`)
    document.body.appendChild(link)
    link.click()
    link.parentNode.removeChild(link)
  }

  const handleCancelMembership = () => {
    if (products?.currentSubscription?.pending) {
      redirectToPortal()
    } else {
      handleCreateCancelOffer()
      setCancelSubModal(true)
    }
  }

  const parseChargedAmount = record => {
    if (
      record.discountable &&
      record.discount_amounts &&
      record.discount_amounts.length > 0
    ) {
      const discount = record.discount_amounts[0]
      if (discount.amount > 0) {
        return record.amount / 100 - discount.amount / 100
      }
    }
    return record.amount / 100
  }

  const nextBillingDate = useMemo(() => {
    if (products?.subscription?.expiry) {
      return moment(products?.subscription?.expiry).format('YYYY-MM-DD')
    }
  }, [products])

  const billingTitle = useMemo(() => {
    if (products?.isSubscriptionCancelled) {
      return 'Expire Date: '
    }
    return 'Next Billing Date: '
  }, [products])

  const buySubscriptionHandler = values => {
    setSubmitLoading(true)
    let data = {
      stripeCustomerId: user?.customer_id,
      userSubscriptionId: user?.user?.subscription,
    }

    axios
      .post(strapiURL + '/auth0/user/validateAndCreateSubscription', data)
      .then(res => {
        const userData = res.data

        if (userData.isCheckoutRequired) {
          window.location.href = '/member-subscription/subscription'
        } else {
          alert('You already have a subscription with us.')

          // Retrive updated subscription
          axios
            .get(
              strapiURL + `/employers/${user?.user?.id}/subscriptionsAndCredits`
            )
            .then(res => {
              dispatch(setProducts(res?.data))
              navigate('/account-candidate')
            })
            .catch(err => {
              console.log(
                'Something wrong while retrive subscriptionsAndCredits.'
              )
            })
        }
      })
      .catch(err => {
        setSubmitLoading(false)
        message.error(err?.response?.data?.message[0].error)
      })
  }

  const transactionCols = [
    {
      title: 'Transaction ID',
      dataIndex: 'transaction_id',
      key: 'transaction_id',
      render: (text, record, index) => {
        return (
          <p className="text-black font-sans font-semibold mb-0 text-last">
            {record.invoice_id}
          </p>
        )
      },
    },
    {
      title: 'Name ',
      dataIndex: 'name',
      key: 'name',
      width: 250,
      render: (text, record, index) => {
        return (
          <p className="text-black font-sans font-semibold mb-0 text-last">
            {record.description}
          </p>
        )
      },
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      render: (text, record, index) => {
        return (
          <p className="inline-block bg-[#DCE9EE] text-[#00567C] text-location text-last font-sans mb-0 px-2 rounded">
            {record?.type === 'subscription' ? 'Recurring' : 'One time'}
          </p>
        )
      },
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
      render: (text, record, index) => {
        return (
          <p className="text-black font-sans font-semibold mb-0 text-last">
            {formatCurrency(parseChargedAmount(record), record.currency)}
          </p>
        )
      },
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      render: (text, record, index) => {
        return (
          <>
            <p className="text-merlot font-sans font-semibold mb-0 text-last">
              {dateFormatHandler(new Date(record.created * 1000))}
            </p>
          </>
        )
      },
    },
    {
      title: 'Payment Status',
      dataIndex: 'status',
      key: 'status',
      render: (text, record, index) => {
        return (
          <p className="text-black font-sans font-semibold mb-0 text-last">
            {record.status}
          </p>
        )
      },
    },
    {
      title: 'Action',
      dataIndex: 'invoice_doc',
      key: 'invoice_doc',
      render: (text, record) => {
        return (
          <a onClick={() => handleDownloadInvoice(record.invoice_doc)}>
            Download
          </a>
        )
      },
    },
  ]
  const transactionColsMobile = [
    {
      title: '',
      dataIndex: 'transaction_id',
      key: 'transaction_id',
      render: (text, record, index) => {
        return (
          <div className="flex flex-col w-full">
            <div className="flex justify-between">
              <div className="flex flex-col mr-3">
                <p className="text-[13px] text-location leading-0 m-0 font-semibold pb-1">
                  Transaction ID
                </p>
                <div className="flex items-center ">
                  <span className="text-[12px] text-black leading-0 ml-1 font-semibold">
                    {record.invoice_id}
                  </span>
                </div>
              </div>
              <div className="flex flex-col mr-3">
                <p className="text-[13px] text-location leading-0 m-0 font-semibold pb-1">
                  Payment Status
                </p>
                <div className="flex items-center">
                  <span className="text-[12px] text-[#52b85b] font-semibold leading-0 ml-1">
                    {record.status}
                  </span>
                </div>
              </div>
            </div>
            <div className="flex justify-between mt-8">
              <div className="flex flex-col mr-3">
                <p className="text-[13px] text-location leading-0 m-0 font-semibold pb-1">
                  Name
                </p>
                <div className="flex items-center w-full lg:w-[60%]">
                  <span className="text-[12px] text-black leading-0 ml-1 font-semibold">
                    {record.description}
                  </span>
                </div>
              </div>
            </div>
            <div className="flex justify-between mt-8">
              <div className="flex flex-col mr-3">
                <p className="text-[13px] text-location leading-0 m-0 font-semibold pb-1">
                  Type
                </p>
                <div className="flex items-center">
                  <p className="inline-block bg-[#DCE9EE] text-[#00567C] text-location text-last font-sans mb-0 px-2 rounded">
                    {record?.type === 'subscription' ? 'Recurring' : 'One time'}
                  </p>
                </div>
              </div>
              <div className="flex flex-col mr-3">
                <p className="text-[13px] text-location leading-0 m-0 font-semibold pb-1">
                  Amount
                </p>
                <div className="flex items-center">
                  <span className="text-[12px] text-black leading-0 ml-1 font-semibold">
                    {formatCurrency(
                      parseChargedAmount(record),
                      record.currency
                    )}
                  </span>
                </div>
              </div>
              <div className="flex flex-col mr-3">
                <p className="text-[13px] text-location leading-0 m-0 font-semibold pb-1">
                  Date
                </p>
                <div className="flex items-center">
                  <span className="text-[12px] text-black leading-0 ml-1 font-semibold w-max">
                    {dateFormatHandler(new Date(record.created * 1000))}
                  </span>
                </div>
              </div>
            </div>
            <div className="flex justify-between mt-8">
              <div className="flex flex-col mr-3">
                <p className="text-[13px] text-location leading-0 m-0 font-semibold pb-1">
                  Action
                </p>
                <div className="flex items-center">
                  <a
                    className="text-last font-sans "
                    onClick={() => handleDownloadInvoice(record.invoice_doc)}
                  >
                    Download
                  </a>
                </div>
              </div>
            </div>
          </div>
        )
      },
    },
  ]

  const redirectToPortal = () => {
    pushCancelSubscriptionEvent(products.subscriptionPurchased?.price?.nickname)

    axios
      .post(strapiURL + '/stripe/session', {
        customer_id: user?.customer_id,
        redirect_url: `${
          typeof window !== 'undefined' && window.location.origin
        }/thankyou`,
        is_employer: false,
      })
      .then(res => {
        if (typeof window !== 'undefined') {
          window.history.replaceState(
            '',
            '',
            `${window.location.origin}/thankyou/`
          )
          window.location.href = res?.data?.session_url
        }
        // navigate(res?.data?.session_url)
      })
      .catch(err => {
        console.log(err)
      })
  }

  return (
    <>
      {!userProfile ? (
        <div
          className="w-full min-h-900px bg-no-repeat bg-cover"
          style={{
            backgroundImage: `url(/loaders/candidate-account.png)`,
          }}
        />
      ) : (
        <div className="container mx-auto pb-24">
          <div className="w-full px-8 lg:px-6 sm:px-0">
            <h1 className="text-left text-2xl text-[#232323] sm:text-5xl font-playfair mb-8 lg:mb-20">
              Account
            </h1>

            <>
              <h1 className="font-sans text-xl lg:text-2xl font-bold mt-4">
                My Membership
              </h1>
              {products?.subscriptionPurchased ? (
                <>
                  <div className="w-full md:w-[60%]">
                    <div className="flex flex-col justify-between lg:flex-row mt-6 mb-5">
                      <div>
                        <div className="flex items-center">
                          <h1 className="font-sans text-base mb-0">
                            {products.currentSubscription.nickname}
                          </h1>
                          <p className="font-sans text-xs lg:ml-4 mb-0 lg:mt-0 mt-3">
                            Date of Purchase:{' '}
                            {PurchaseDate && (
                              <span className="font-bold">
                                {' '}
                                {moment(PurchaseDate).format('YYYY-MM-DD')}
                              </span>
                            )}
                          </p>
                        </div>
                        <div className="flex flex-col lg:flex-row lg:items-center mt-2">
                          <p className="text-last font-sans mb-0 px-3 py-1 rounded text-[#6D1E36] bg-[#FFF0F0] mr-5 w-fit">
                            {billingTitle}
                            <span className="font-bold">{nextBillingDate}</span>
                          </p>

                          {!products?.isSubscriptionCancelled &&
                          products?.subscription?.subscription ? (
                            <u
                              onClick={() => redirectToPortal()}
                              className="text-jobTag lg:mx-3 cursor-pointer mt-3 lg:mt-0"
                            >
                              Update Payment Details
                            </u>
                          ) : (
                            ''
                          )}
                          {!products?.isSubscriptionCancelled &&
                          products?.subscription?.subscription ? (
                            <u
                              className="text-jobTag cursor-pointer mt-3 lg:mt-0"
                              onClick={handleCancelMembership}
                              id={
                                products?.currentSubscription?.pending
                                  ? 'cancelMembership'
                                  : ''
                              }
                            >
                              Cancel Membership
                            </u>
                          ) : (
                            ''
                          )}
                        </div>
                      </div>

                      {products?.isSubscriptionCancelled ? (
                        <div className="text-right">
                          <p className="text-lg font-sans px-3 mb-2 text-[#6D1E36] font-bold">
                            CANCELED
                          </p>
                          <p className="text-xs	font-sans italic mb-2 text-[#6D1E36]">
                            Canceled on{' '}
                            {dateFormatHandler(
                              new Date(products?.subscription?.cancelled_at)
                            )}
                          </p>
                          {!products?.subscription?.scheduled_sub && (
                            <button
                              onClick={() => redirectToPortal()}
                              className="text-white py-3 px-6 bg-button cursor-pointer font-sans font-bold rounded"
                            >
                              Reactivate
                            </button>
                          )}
                        </div>
                      ) : null}
                    </div>

                    <div>
                      {products?.subscription?.isGracePeriod && (
                        <div className="flex flex-col lg:flex-row lg:items-center">
                          <p className="text-last font-sans mt-2 px-3 py-1 rounded text-[#6D1E36] mr-5 w-fit">
                            <span
                              style={{
                                fontStyle: `italic`,
                              }}
                            >
                              Payment processing
                            </span>
                          </p>
                        </div>
                      )}
                    </div>
                    {products?.subscription?.scheduled_sub && (
                      <div>
                        <p className="text-xs	font-sans italic mb-2 text-[#6D1E36]">
                          * Your plan will switch to{' '}
                          <b>Searchable Profile Only</b> product on{' '}
                          {dateFormatHandler(
                            new Date(products?.subscription?.expiry)
                          )}
                          . Click{' '}
                          <a
                            className="text-[#6D1E36] underline"
                            onClick={() => setCancelScheModal(true)}
                          >
                            Here
                          </a>{' '}
                          to cancel this scheduled change.
                        </p>
                      </div>
                    )}
                  </div>
                </>
              ) : (
                <div className="flex items-center">
                  <p className="inline-block bg-[#FFF8F8] text-merlot text-last font-sans mb-0 px-2 py-1 rounded">
                    No subscription found
                  </p>
                  <button
                    type="button"
                    onClick={buySubscriptionHandler}
                    className="text-jobTag mx-3 cursor-pointer underline"
                  >
                    {submitLoading ? 'Please wait...' : 'Buy subscription'}
                  </button>
                </div>
              )}
              <div>
                {' '}
                <h1 className="font-sans text-2xl font-bold mt-14 mb-8">
                  Transaction History
                </h1>
                <div className="shadow-contact-card rounded w-full md:w-[60%] overflow-auto">
                  <Table
                    className="hide-table-header-background hide-table-header-cols-border table-with-pagination"
                    columns={
                      largeScreen ? transactionCols : transactionColsMobile
                    }
                    dataSource={invoices}
                    bordered={false}
                    loading={loading ? true : false}
                  />
                </div>
              </div>

              <div>
                <h1 className="font-sans text-2xl font-bold mt-14 mb-8">
                  Manage Your Account
                </h1>
                <p className="text-xs font-semibold">
                  Update your email address{' '}
                  <span className="text-[#C40000]">*</span>
                </p>
                <div className="flex flex-col">
                  <div className="w-full sm:w-[35%] relative theme-form-item mb-8">
                    <input
                      ref={emailInputRef}
                      defaultValue={userProfile?.user?.email || ''}
                      placeholder="Enter Your Email Address"
                      className="placeholder-[#C4C4C4] focus:border-merlot focus:outline-none focus:shadow-none w-full"
                    />

                    <div className="absolute right-0 top-0 bottom-0 flex items-center">
                      <button
                        onClick={emailChangeHandler}
                        type="button"
                        className="bg-voyage py-1 px-3 font-sans text-white text-last font-bold rounded mr-2 "
                      >
                        {emailLoading ? 'Updating...' : 'Update'}
                      </button>
                    </div>
                  </div>
                  <button
                    onClick={() => setModalOpen(true)}
                    type="button"
                    className="bg-voyage sm:mt-0 w-full lg:w-[30%] py-3 px-3 font-sans text-white text-last font-bold rounded"
                  >
                    Update your password
                  </button>
                </div>
              </div>
            </>
          </div>
          <CandidateUpdateImageModal
            setUserProfile={setUserProfile}
            userProfile={userProfile}
            imageModalOpen={imageModalOpen}
            setImageModalOpen={setImageModalOpen}
          />
          <UpdatePasswordModal
            setModalOpen={setModalOpen}
            modalOpen={modalOpen}
            userProfile={userProfile}
          />
          <CandidateCancelModal
            visible={cancelSubModal}
            onClose={() => {
              handleUpdateCancelOffer(null)
              setCancelSubModal(false)
            }}
            onChangePlan={() => {
              handleUpdateCancelOffer(true)
              setCancelSubModal(false)
              handleLoadProducts()
            }}
            onProceedCancel={() => {
              setCancelSubModal(false)
              redirectToPortal()
            }}
          />
          <CandidateKeepCancelModal
            visible={cancelScheModal}
            onClose={() => setCancelScheModal(false)}
            onOk={() => {
              setCancelScheModal(false)
              handleLoadProducts()
            }}
            onCancel={() => setCancelScheModal(false)}
          />
        </div>
      )}
    </>
  )
}
export default CandidateAccount
