import React from 'react'
import CandidateAccount from '../components/pageComponents/account/candidate'
import Wrapper from '../components/wrapper'
import PageNav from '../components/pageComponents/common/pageNav'

const Profile = ({ location }) => {
  const Children = props => {
    return (
      <>
        <PageNav navItem="Account" />
        <CandidateAccount {...props} />
      </>
    )
  }

  return (
    <Wrapper
      privateRoute={true}
      title="Account | MedReps.com"
      location={location}
    >
      <Children />
    </Wrapper>
  )
}
export default Profile
