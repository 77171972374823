import React, { useState } from 'react'
import { Modal, message } from 'antd'
import axios from 'axios'
import { strapiURL } from '../../config'
import { CloseModalIcon } from '../icons'

const CandidateCancelModal = ({
  visible,
  onClose,
  onChangePlan,
  onProceedCancel,
}) => {
  const [loading, setLoading] = useState(false)

  const handleDowngrade = async () => {
    setLoading(true)
    await axios.post(strapiURL + '/stripe/candidateChangePlan')
    message.success('Successfully downgraded to Searchable Profile')
    setTimeout(() => {
      setLoading(false)
      onChangePlan()
    }, 3000)
  }

  return (
    <Modal
      width={600}
      footer={null}
      visible={visible}
      okText="Save"
      className="p-0 candidateAlertModal shadow-lg"
    >
      <div className="relative bg-white rounded-br px-4 pt-4 pb-4 flex flex-col items-center rounded-md">
        <div
          className="absolute right-4 top-4 cursor-pointer"
          onClick={onClose}
        >
          <CloseModalIcon />
        </div>

        <h4 className="text-voyage font-bold text-lg">
          Stay connected to top employers and recruiters—keep your profile
          active for $4.99 per month
        </h4>
        <p className="font-sans text-center mb-0 text-voyage  text-md mt-2">
          Change into this subscription plan to keep your profile visible to
          employers and recruiters looking for your unique sales talent. You
          won’t be able to search and apply for jobs unless you change back to a
          full plan.
        </p>
        <p className="font-sans text-center mb-0 text-voyage  text-md mt-2">
          After clicking "Change Plan" at the end of your current subscription
          term you will switch to the Searchable Profile Only plan. You will
          retain access to your current services until that time. You will then
          be charged $4.99 monthly until you cancel.
        </p>

        <div className="flex items-center justify-center mt-6 w-11/12  mx-auto">
          <button
            onClick={handleDowngrade}
            className="text-white py-4 px-10 bg-voyage cursor-pointer font-sans font-bold rounded mr-8"
          >
            {loading ? 'Changing...' : 'CHANGE PLAN'}
          </button>

          <button
            id="cancelMembership"
            onClick={onProceedCancel}
            className="bg-bgSecondaryButton text-voyage sm:w-auto text-sm font-bold font-sans py-4 px-10 rounded"
          >
            PROCEED TO CANCEL
          </button>
        </div>
      </div>
    </Modal>
  )
}

export default CandidateCancelModal
